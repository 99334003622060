(function($) {
  var hpEmail = document.querySelector('#homepage-email');
  if (hpEmail) {
    var hpEmailValue = hpEmail.querySelector('[type=email]');
    hpEmail.addEventListener('submit', function(e) {
      e.preventDefault();
      window.open(`/join-our-email-list?email=${hpEmailValue.value}`);
    });
  } else {
    var signupEmail = document.querySelector('#mc_embed_signup #mce-EMAIL');
    if (signupEmail && window.location.search) {
      var params = new URLSearchParams(window.location.search);
      if (params.get('email')) signupEmail.value = params.get('email');
    }
  }
})(jQuery);
